import React from 'react';
import { HashRouter, Route, Switch, } from 'react-router-dom';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const IndexStructure = React.lazy(() => import('./Structure'));
function App() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/" name="IndexStructure" render={props => <IndexStructure {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
